import { makeStyles } from "@material-ui/core/styles";
import React from "react";

interface PageTitleProps {
  header: string;
  subheader: string;
}

export default function PageTitle(props: PageTitleProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{props.header}</h1>
      <div className={classes.subtitle}>{props.subheader}</div>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    marginTop: "64px",
    marginBottom: "96px",
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "center",
  },
  title: {
    fontSize: "48px",
    fontWeight: 600,
    fontFamily: "Nunito Sans",
    color: "#222",
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Nunito Sans",
    color: "#222",
    marginTop: 20,
  },
});

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import NewsletterRow from "../newsletter/NewsletterRow";
import PageTitle from "../core/PageTitle";
import React from "react";
// import { Tweet } from "react-twitter-widgets";
import Typography from "@material-ui/core/Typography";
import { RouteComponentProps } from "@reach/router";
import Layout from "../core/Layout";
import FeaturedJobsSection from "../jobs/FeaturedJobsSection";
import MakerOSSection from "../ads/MakerOSSection";
import Tweet from "../../tailwindComponents/Tweet";
import { getImage } from "gatsby-plugin-image";

interface QuickBitesComponentProps extends RouteComponentProps {
  pageContext: {
    featuredJobs: any[];
    quickbitesArray: any[];
  };
}

function QuickBitesComponent(props: QuickBitesComponentProps) {
  const classes = useStyles();
  return (
    <Layout
      maxWidth="xl"
      pageTitle="Jetpack Compose Quick Bites | JetpackCompose.app by Vinay Gaba"
      pageDescription="Learn more about Jetpack Compose. with easy to consume bites of knowledge! Jetpack Compose is the best way to build for Android."
      pageSlug="/quick-bites"
      pageImageUrl="/quick_bites.png"
      seoImageUrl="/quick_bites.png"
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <Grid container justify="center">
            <Grid key={"header"} item xs={12} md={4}>
              <PageTitle
                header="Compose Quick Bites"
                subheader=" Learn more about the core concepts of Jetpack Compose right
                    from your Twitter feed!"
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            spacing={2}
            className={classes.quickBitesContainer}
          >
            <Grid key={"issue1"} item xs={12} md={4}>
              <Typography className={classes.issueTitle} align="center">
                Issue #1: What is declarative UI?
              </Typography>
              <Tweet
                name={props.pageContext.quickbitesArray[0].name}
                username={props.pageContext.quickbitesArray[0].username}
                tweetUrl={props.pageContext.quickbitesArray[0].tweetUrl}
                tweetBody={props.pageContext.quickbitesArray[0].tweetBody}
                userImage={props.pageContext.quickbitesArray[0].userImage}
                tweetImage={props.pageContext.quickbitesArray[0].tweetImage}
              />
            </Grid>
            <Grid key={"issue2"} item xs={12} md={4}>
              <Typography className={classes.issueTitle} align="center">
                Issue #2: What is this @Composable thing?
              </Typography>
              <Tweet
                name={props.pageContext.quickbitesArray[1].name}
                username={props.pageContext.quickbitesArray[1].username}
                tweetUrl={props.pageContext.quickbitesArray[1].tweetUrl}
                tweetBody={props.pageContext.quickbitesArray[1].tweetBody}
                userImage={props.pageContext.quickbitesArray[1].userImage}
                tweetImage={props.pageContext.quickbitesArray[1].tweetImage}
              />
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Grid key={"issue3"} item xs={12} md={4} justify="center">
              <Typography className={classes.issueTitle} align="center">
                Issue #3: Why should you care about recomposition?
              </Typography>
              <Tweet
                name={props.pageContext.quickbitesArray[2].name}
                username={props.pageContext.quickbitesArray[2].username}
                tweetUrl={props.pageContext.quickbitesArray[2].tweetUrl}
                tweetBody={props.pageContext.quickbitesArray[2].tweetBody}
                userImage={props.pageContext.quickbitesArray[2].userImage}
                tweetImage={props.pageContext.quickbitesArray[2].tweetImage}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={2}>
          <div className={classes.jobs}>
            <MakerOSSection />
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles({
  quickBitesContainer: {
    padding: 16,
  },
  issueTitle: {
    fontSize: 25,
    fontFamily: "Nunito Sans",
    fontWeight: 400,
    marginBottom: "2%",
    marginTop: "5%",
    color: "#222",
  },
  newsletter: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    textAlign: "center",
    marginTop: 64,
  },
  jobs: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    marginTop: 64,
  },
  footer: {
    marginTop: "2%",
    height: "11.5rem",
    width: "100%",
    bottom: 0,
    position: "absolute",
  },
});

export default QuickBitesComponent;
